import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Table,
  Modal,
  Form,
  InputGroup,
  FormControl,

} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "src/services/axios";

interface Folder {
  id: number;
  name: string;
  fileCount: number;
}

const FolderDocumentsCompany: React.FC = () => {
  const params = useParams()

  const [folders, setFolders] = useState<Folder[]>([
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value);

  const filteredFolders = folders.filter((folder) => folder.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setNewFolderName("");
  };

  const handleCreateFolder = () => {
    api.post('/companies/folders', {
      name: newFolderName,
      company_id: params.id
    }).then(() => {
      fetchFolders()
      handleCloseModal();
    })
      .catch(() => toast.error("Erro ao criar pasta"))
  };

  const fetchFolders = async () => {
    try {
      const response = await api.get(`/companies/${params.id}/folders`)
      setFolders(response.data);
    } catch (error) {
      toast.error("Erro ao buscar pastas")
    }
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  return (
    <Container fluid>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center" as="h5">
          <h5>Gerenciador de Pastas</h5>
          <Button variant="success" onClick={handleOpenModal}>
            <i className="bi bi-plus-lg me-2"></i> Nova Pasta
          </Button>
        </Card.Header>
        <Card.Body>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Pesquisar pastas..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>

          <Table responsive hover>
            <thead>
              <tr>
                <th>Nome</th>
              
              </tr>
            </thead>
            <tbody>
              {filteredFolders.map((folder) => (
                <tr key={folder.id} style={{ cursor: "pointer" }}>
                  <td>
                    <Link to={`/empresas/${params.id}/pastas/${folder.id}`} className="text-decoration-none text-dark">
                      <i className="bi bi-folder-fill text-warning me-2 mr-3"></i>
                      {folder.name}
                    </Link>
                  </td>
               
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Modal para Criar Nova Pasta */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Criar Nova Pasta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFolderName">
              <Form.Label>Nome da Pasta</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome da pasta"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={handleCreateFolder}
            disabled={!newFolderName.trim()}
          >
            Criar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FolderDocumentsCompany;
