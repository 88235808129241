import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import EstagiarioForm from '../screens/Estagiario/Form'
import EmpresaForm from '../screens/Empresa/Form';
import EmpresaList from '../screens/Empresa/List';
import TCEList from '../screens/Estagiario/List';
import ObservationsList from 'src/screens/Observacoes/List';
import ContratosForm from 'src/screens/Contratos/Form';
import ContratosList from 'src/screens/Contratos/List';
import DesligamentoForm from 'src/screens/Desligamento';
import Login from 'src/screens/Login';
import ContratosAtivosReport from 'src/screens/Relatorios/ContratosAtivos';
import Dashboard from 'src/screens/Dashboard';
import ConvitesForm from '../screens/Convite'
import InactivesContractsReport from 'src/screens/Relatorios/ContratosInativos';
import Success from 'src/screens/Success';

import CourseList from 'src/screens/Courses/List';
import CoursesForm from 'src/screens/Courses/Form';
import AtividadesCursoList from 'src/screens/AtividadesCurso/List';
import ExpiredContracts from 'src/screens/Relatorios/ContratosExpirados';
import Checklist from 'src/screens/Checklist';
import DocumentsTrainnes from 'src/screens/DocumentosEstagiarios';
import ConvitesDocumentos from 'src/screens/Convite/documentos';
import AttendaceList from 'src/screens/Presenças/List';
import FinancialReport from 'src/screens/Relatorios/Financeiro';
import AttendanceReport from 'src/screens/Relatorios/Presencas/List';
import ChargesList from 'src/screens/Juno/Boletos/List';
import ChargesForm from 'src/screens/Juno/Boletos/Form';
import AttendanceReportCount from 'src/screens/Relatorios/PresencasContador/List';
import AttendanceReportCompany from 'src/screens/Empresas/Presencas';
import LoginParceiros from 'src/screens/Parceiros';
import DocumentsEmpresas from 'src/screens/Empresas/Documentos';
import PresencasContadorEmpresa from 'src/screens/Empresas/PresencasContador';
import DocumentsCompanies from 'src/screens/DocumentosEmpresas';
import InstituicaoEducacaoList from 'src/screens/InstituicoesEducacao/List';
import InstituicaoEducacaoForm from 'src/screens/InstituicoesEducacao/Form';

import GroupForm from 'src/screens/Grupos/Form'
import GroupList from 'src/screens/Grupos/List';
import ContactList from 'src/screens/Contacts/List';
import ContactForm from 'src/screens/Contacts/Form';
import Schedule from 'src/screens/Schedule/List';
import { Profile } from 'src/screens/Profile';
import { UserList } from 'src/screens/ListaUsuarios';

import { Solicitations } from 'src/screens/Empresas/solicitacoes';
import RenovacaoForm from 'src/screens/Renovacao';

import CompaniesSupervisorForm from 'src/screens/Empresa/CompaniesSupervisorForm'
import InstituicoesEducacaoSupervisorForm from 'src/screens/InstituicoesEducacao/InstituicoesEducacaoSupervisorForm';
import FolderDocumentsCompany from 'src/screens/Folders/List';

export default function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={Login}
      />

      <Route
        exact
        path="/parceiros"
        component={LoginParceiros}
      />

      <Route
        exact
        path="/dashboard"
        component={Dashboard}
        isPrivate
      />

      <Route
        exact
        path="/agenda"
        component={Schedule}
        isPrivate
      />

      <Route
        exact
        path="/instituicao_educacao"
        component={InstituicaoEducacaoList}
        isPrivate
      />

      <Route
        exact
        path="/instituicao_educacao/novo"
        component={InstituicaoEducacaoForm}
        isPrivate
      />

      <Route
        exact
        path="/instituicao_educacao/:id"
        component={InstituicaoEducacaoForm}
        isPrivate
      />

      <Route
        exact
        path="/financeiro/boletos"
        component={ChargesList}
        isPrivate
      />

      <Route
        exact
        path="/financeiro/boletos/novo"
        component={ChargesForm}
        isPrivate
      />

      <Route
        exact
        path="/financeiro/boletos/:id"
        component={ChargesForm}
        isPrivate
      />


      <Route
        exact
        path="/estagiarios/novo"
        component={EstagiarioForm}
        isPrivate
      />

      <Route
        exact
        path="/estagiarios/documentos/:id"
        component={DocumentsTrainnes}
        isPrivate
      />


      <Route
        exact
        path="/empresas/:id/pastas/:folderId"
        component={DocumentsCompanies}
        isPrivate
      />

      <Route
        exact
        path="/estagiarios/:id"
        component={EstagiarioForm}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/empresas/presencas"
        component={AttendanceReportCompany}
        isPrivate
      />


      <Route
        exact
        path="/relatorios/empresas/documentos/:id"
        component={DocumentsEmpresas}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/empresas/contagem"
        component={PresencasContadorEmpresa}
        isPrivate
      />

      <Route
        exact
        path="/empresas/novo"
        component={EmpresaForm}
        isPrivate
      />
      <Route
        exact
        path="/empresas/:id"
        component={EmpresaForm}
        isPrivate
      />
      <Route
        exact
        path="/empresas"
        component={EmpresaList}
        isPrivate
      />
      <Route
        exact
        path="/estagiarios"
        component={TCEList}
        isPrivate
      />
      <Route
        exact
        path="/observacoes/:id"
        component={ObservationsList}
        isPrivate
      />

      <Route
        exact
        path="/contratos"
        component={ContratosList}
        isPrivate
      />

      <Route
        exact
        path="/contratos/novo"
        component={ContratosForm}
        isPrivate
      />

      <Route
        exact
        path="/contratos/:id"
        component={ContratosForm}
        isPrivate
      />

      <Route
        exact
        path="/contratos/renovacao/:id"
        component={RenovacaoForm}
        isPrivate
      />

      <Route
        exact
        path="/desligamentos/:id"
        component={DesligamentoForm}
        isPrivate
      />


      <Route
        exact
        path="/presencas/:id"
        component={AttendaceList}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/financeiro"
        component={FinancialReport}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/contratos_ativos"
        component={ContratosAtivosReport}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/contratos_expirados"
        component={ExpiredContracts}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/contratos_desligados"
        component={InactivesContractsReport}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/presencas/contagem"
        component={AttendanceReportCount}
        isPrivate
      />

      <Route
        exact
        path="/checklist/:id"
        component={Checklist}
        isPrivate
      />

      <Route
        exact
        path="/convites/registro/:inviteApplication"
        component={ConvitesForm}
      />

      <Route
        exact
        path="/convites/documentos"
        component={ConvitesDocumentos}
      />

      <Route
        exact
        path="/convites/sucesso"
        component={Success}
      />
      <Route
        exact
        path="/cursos"
        component={CourseList}
        isPrivate
      />

      <Route
        exact
        path="/cursos/:id/atividades"
        component={AtividadesCursoList}
        isPrivate
      />

      <Route
        exact
        path="/cursos/novo"
        component={CoursesForm}
        isPrivate
      />
      <Route
        exact
        path="/cursos/:id"
        component={CoursesForm}
        isPrivate
      />

      <Route
        exact
        path="/relatorios/presencas"
        component={AttendanceReport}
        isPrivate
      />


      <Route
        exact
        path="/grupos/novo"
        component={GroupForm}
        isPrivate
      />

      <Route
        exact
        path="/grupos/:id"
        component={GroupForm}
        isPrivate
      />

      <Route
        exact
        path="/grupos"
        component={GroupList}
        isPrivate
      />

      <Route
        exact
        path="/contatos"
        component={ContactList}
        isPrivate
      />
      <Route
        exact
        path="/contatos/novo"
        component={ContactForm}
        isPrivate
      />

      <Route
        exact
        path="/contatos/:id"
        component={ContactForm}
        isPrivate
      />

      <Route
        exact
        path="/perfil"
        component={Profile}
        isPrivate
      />

      <Route
        exact
        path="/lista_usuarios"
        component={UserList}
        isPrivate
      />

      <Route
        exact
        path="/solicitacoes"
        component={Solicitations}
        isPrivate
      />

      <Route
        exact
        path="/empresas/supervisores/:id"
        component={CompaniesSupervisorForm}
        isPrivate
      />

      <Route
        exact
        path="/instituicao_educacao/supervisores/:id"
        component={InstituicoesEducacaoSupervisorForm}
        isPrivate
      />

      <Route
        exact
        path="/empresas/:id/pastas"
        component={FolderDocumentsCompany}
        isPrivate
      />
    </Switch>
  );
}